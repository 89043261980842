var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    [
      _vm._t("arrow-row"),
      _vm.selection
        ? _c(
            "td",
            {
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _vm._t(
                "selection",
                function () {
                  return [
                    _c("checkbox", {
                      attrs: {
                        value: _vm.item.selected,
                        disabled: _vm.item.isSelectionDisabled,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("select", _vm.item, $event)
                        },
                      },
                    }),
                  ]
                },
                { item: _vm.item, index: _vm.itemIndex }
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._l(_vm.cols, function (col, index) {
        return [
          !col.hidden
            ? _c(
                "td",
                { key: index, class: col.class },
                [
                  _vm._t(
                    col.name,
                    function () {
                      return [_vm._v(_vm._s(_vm.item[col.name]))]
                    },
                    { item: _vm.item, index: _vm.itemIndex }
                  ),
                ],
                2
              )
            : _vm._e(),
        ]
      }),
      _vm.actions
        ? _c(
            "td",
            { class: _vm.actions.colClass },
            [_vm._t("actions", null, { item: _vm.item, index: _vm.itemIndex })],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }