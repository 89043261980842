var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table table-striped pxl-table" },
    [
      !_vm.withArrow
        ? _c(
            "colgroup",
            { staticClass: "default-colgroup" },
            [
              _vm.selection
                ? _c("col", { class: _vm.selection.colClass })
                : _vm._e(),
              _vm._l(_vm.cols, function (col, index) {
                return [
                  !col.hidden
                    ? _c("col", { key: index, class: col.class })
                    : _vm._e(),
                ]
              }),
              _vm.actions
                ? _c("col", { class: _vm.actions.colClass })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c("table-head", {
        attrs: { cols: _vm.cols, "selected-options": _vm.selectedOptions },
        scopedSlots: _vm._u(
          [
            _vm.withArrow
              ? {
                  key: "arrow-head",
                  fn: function () {
                    return [_c("th", { staticClass: "w-2" })]
                  },
                  proxy: true,
                }
              : null,
            _vm.selection
              ? {
                  key: "selection",
                  fn: function () {
                    return [
                      _c(
                        "th",
                        { class: _vm.selection.colClass },
                        [
                          _c("checkbox", {
                            attrs: {
                              value: _vm.allSelectCheckboxIsSelected,
                              indeterminate:
                                _vm.allSelectCheckboxIsIndeterminate,
                            },
                            on: { input: _vm.onSelectAll },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
            _vm.actions
              ? {
                  key: "actions",
                  fn: function () {
                    return [
                      _c("th", { class: _vm.actions.colClass }, [
                        _c("div", [_c("span", [_vm._v("Actions")])]),
                      ]),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c(
        "tbody",
        [
          _vm._l(_vm.tableItems, function (item, itemIndex) {
            return [
              _c("table-row", {
                key: item.id,
                attrs: {
                  cols: _vm.cols,
                  item: item,
                  "item-index": itemIndex,
                  selection: _vm.selection,
                  actions: _vm.actions,
                },
                on: { select: _vm.onItemSelect },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onRowClick(item)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm.withArrow
                      ? {
                          key: "arrow-row",
                          fn: function () {
                            return [
                              _c("td", { class: ["arrow-td", "w-2"] }, [
                                _c("i", {
                                  class: [
                                    "fa",
                                    "fa-angle-right",
                                    {
                                      rotate:
                                        _vm.viewedItem &&
                                        _vm.viewedItem.id === item.id,
                                    },
                                  ],
                                }),
                              ]),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    _vm._l(_vm.$scopedSlots, function (index, name) {
                      return {
                        key: name,
                        fn: function (data) {
                          return [_vm._t(name, null, null, data)]
                        },
                      }
                    }),
                  ],
                  null,
                  true
                ),
              }),
              _vm.viewedItem && _vm.viewedItem.id === item.id
                ? _c(
                    "tr",
                    { staticClass: "details-section" },
                    [_vm._t("rowViewedItemDetails", null, { item: item })],
                    2
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }