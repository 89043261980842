<template lang="pug">
    .itemsPerPage
        select(v-model="selectedValue" @change="change")
            option(v-for="option in options" :value="option.id") {{ option.name }}
        span Per Page
</template>

<script>
import { root } from "@/constants";

export default {
  name: "ItemsPerPage",
  props: {
    selected: {
      type: Number,
      required: false,
      default: function () {
        const queryLimit = this.$route && this.$route.query.limit;

        return Number(queryLimit) || root.pagination.DEFAULT_COUNT;
      },
    },
    options: {
      type: Array,
      required: false,
      default: () => root.pagination.itemsPerPage,
    },
  },
  data() {
    return {
      selectedValue: this.selected,
    };
  },
  watch: {
    selected(value) {
      this.selectedValue = value;
    },
    "$route.query.limit"(value) {
      this.selectedValue = Number(value);
    },
  },
  methods: {
    change() {
      this.$emit("change", this.selectedValue);
      this.$updateQuery({ limit: this.selectedValue, offset: 0 });
    },
  },
};
</script>
