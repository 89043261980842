import _ from "lodash";

export const getErrorMessage = (error, msg) => {
  const defaultMsg = msg || "Some error occurred. Try to refresh page and edit again";

  const errorData = error?.data;

  if (_.get(error, "data.errors.length", 0) > 0) {
    return errorData.errors.map((err) => {
      return err.clientMessage || err.details || defaultMsg;
    });
  }

  return errorData?.clientMessage || errorData?.details || defaultMsg;
};

export const logErrorHandler = (error) => {
  // eslint-disable-next-line no-console
  console.error("Error occurred", { error });
};

export const handleActionError = (error, vm, action) => {
  const toastErr = (vm, msg) => {
    const options = { title: "Oops", message: msg };

    vm && vm.$toaster.error(options);
  };
  const actionMessage = action && `An error occurred during ${action}`;
  const errorMessage = getErrorMessage(error, actionMessage);

  if (_.isArray(errorMessage)) {
    errorMessage.forEach((msg) => setTimeout(() => toastErr(vm, msg), 500));
  } else {
    toastErr(vm, errorMessage);
  }
};
