<template lang="pug">
    tr
        slot(name="arrow-row")
        td(v-if="selection" @click.stop="")
            slot(name="selection" :item="item" :index="itemIndex")
                checkbox(:value="item.selected" :disabled="item.isSelectionDisabled" @input="$emit('select', item, $event)")
        template(v-for="(col, index) in cols")
            td(v-if="!col.hidden" :key="index" :class="col.class")
                slot(:name="col.name" :item="item" :index="itemIndex")
                    | {{ item[col.name] }}
        td(v-if="actions" :class="actions.colClass")
            slot(name="actions" :item="item" :index="itemIndex")
</template>

<script>
import { Checkbox } from "@/components/shared";

export default {
  name: "TableRow",
  components: { Checkbox },
  props: {
    cols: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    itemIndex: {
      type: Number,
      required: true,
    },
    selection: {
      type: Object,
      default: null,
    },
    actions: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
